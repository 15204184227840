// extracted by mini-css-extract-plugin
export var container = "PageAirportTransfer-module--container--3In1r";
export var image = "PageAirportTransfer-module--image--1uFR8";
export var masks = "PageAirportTransfer-module--masks--Da_HO";
export var leftMask = "PageAirportTransfer-module--leftMask--1vGCF";
export var rightMask = "PageAirportTransfer-module--rightMask--T0tkN";
export var line = "PageAirportTransfer-module--line--Vv8W4";
export var content = "PageAirportTransfer-module--content--1bUAN";
export var main = "PageAirportTransfer-module--main--1MJHf";
export var text = "PageAirportTransfer-module--text--1lOtq";
export var links = "PageAirportTransfer-module--links--2gUe5";
export var link = "PageAirportTransfer-module--link--19gSs";
export var dots = "PageAirportTransfer-module--dots--2IIlY";
export var fullWidthButton = "PageAirportTransfer-module--fullWidthButton--1Itdw";