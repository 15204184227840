import React from 'react';
import cx from 'classnames';

import * as styles from './PageAirportTransfer.module.scss';
import { FluidImage, Link, TextParser } from '../../components';
import { IPageContext, IPageAirportTransfer } from '../../interfaces';
import { dots } from '../../icons';

interface IProps {
  pageAirportTransfer: IPageAirportTransfer;
  pageContext: IPageContext;
}

const PageAirportTransfer = ({ pageAirportTransfer, pageContext }: IProps) => {
  const { image, title, text, bookLink } = pageAirportTransfer;
  return (
    <div className='vertical-margin' data-aos='fade-up'>
      <Link
        langPath={pageContext.langPath}
        to={'tour/flybus' /* bookLink.page.slug */}
      >
        <h2 className={cx('centered-content', 'element-title')}>{title}</h2>
      </Link>
      <div className={styles.container}>
        <FluidImage
          fluid={image.fluid}
          className={styles.image}
          alt={image.title}
          align='bottom'
        />

        <div className={styles.masks}>
          <div className={styles.leftMask} />
          <div className={styles.rightMask}>
            <div data-aos='fade-right' className={styles.line} />
          </div>
        </div>

        <div className={styles.content}>
          <div className='centered-content'>
            <div className={styles.main}>
              <TextParser
                source={text.text}
                darkMode={true}
                className={styles.text}
              />
              <img src={dots} alt='Dots' className={styles.dots} />
            </div>
            <div className={styles.links}>
              <Link
                langPath={pageContext.langPath}
                to={'tour/flybus' /* bookLink.page.slug */}
                className='basic-button'
              >
                {bookLink.title}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Link
        langPath={pageContext.langPath}
        to={'tour/flybus' /* bookLink.page.slug */}
        className={cx('basic-button', styles.fullWidthButton)}
      >
        {bookLink.title}
      </Link>
    </div>
  );
};

export default PageAirportTransfer;
