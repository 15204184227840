import React from 'react';

import { IPageStaffDepartments } from '../../interfaces';
import * as styles from './PageStaffDepartment.module.scss';

interface IProps {
  pageStaffDepartments: IPageStaffDepartments;
}

const PageStaffDepartments = (props: IProps) => {
  const { title, staff } = props.pageStaffDepartments;
  return (
    <div className='centered-content vertical-margin'>
      <h2 className='element-title'>{title}</h2>
      <table
        className={styles.table}
        summary='column one shows the name of the employee, column 2 show employers email, column 3 shows employers phone number, column 4 show job title'
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone number</th>
            <th>Job title</th>
          </tr>
        </thead>
        <tbody>
          {staff.map((person) => {
            return (
              <tr key={person.id}>
                <td>{person.name}</td>
                <td>{person.email}</td>
                <td>{person.phone}</td>
                <td>{person.jobTitle}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PageStaffDepartments;
