import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';

import {
  IPageContext,
  IReducers,
  ICurrencyOption,
  ITourListItem,
  IPagePrivateToursOnly,
} from '../../interfaces';
import { TourList, Link } from '../../components';

interface IProps {
  pageTours: IPagePrivateToursOnly;
  pageContext: IPageContext;
  darkMode?: boolean;
  currency: ICurrencyOption;
  carousel?: boolean | false;
  lang: string;
}

interface IState {
  tours: ITourListItem[];
  loading: boolean;
}

class PagePrivateToursOnly extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { pageTours } = props;

    const tours = pageTours.privateTours.map((tour) => ({
      contentfulTour: tour,
      product: null,
    }));
    this.state = { tours, loading: false };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const { pageContext, currency, carousel } = this.props;
    const { tours, loading } = this.state;
    return (
      <div
        data-aos='fade-up'
        className={cx('centered-content', 'vertical-margin')}
      >
        {this.renderTitle()}
        <TourList
          tours={tours}
          loading={loading}
          langPath={pageContext.langPath}
          currency={currency}
          carousel={carousel}
          privateTours={true}
        />
      </div>
    );
  }

  renderTitle() {
    const { pageTours, darkMode, pageContext } = this.props;
    const { title, link, queryParams } = pageTours;
    const text = (
      <h2 className='element-title' style={darkMode ? { color: 'white' } : {}}>
        {title}
      </h2>
    );

    return link ? (
      <Link
        langPath={pageContext.langPath}
        to={link.slug}
        queryParams={queryParams}
      >
        {text}
      </Link>
    ) : (
      text
    );
  }
}

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { lang } = reducers.translationReducers;
  return { currency, lang };
};

export default connect(mapStateToProps)(PagePrivateToursOnly);
