import React from 'react';
import * as styles from './Marker.module.scss';
import { starWhite } from '../../icons';
import { busLight } from '../../icons';
import InfoBubble from './InfoBubble.tsx';
import { IMarker } from '../../interfaces';

interface IProps {
  marker: Marker;
  onDeselect?: () => void;
  onSelect?: () => void;
  selected?: boolean;
}

enum MarkerIcons {
  BUS = 'bus',
  STAR = 'star',
}

const Marker = ({ marker, onDeselect, onSelect, selected }: IProps) => (
  <>
    {selected && <InfoBubble marker={marker} onClose={onDeselect} />}{' '}
    <div
      className={styles.marker}
      style={{ backgroundColor: marker.color }}
      alt={marker.title}
      onClick={onSelect}
    >
      <img
        src={marker.icon === MarkerIcons.STAR ? starWhite : busLight}
        alt='Star'
        className={styles.icon}
      />
    </div>
  </>
);

export default Marker;
