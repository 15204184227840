import React from 'react';

import { IPageFaqCategory } from '../../interfaces';
import { FaqList } from '../../components';

interface IProps {
  pageFaqCategory: IPageFaqCategory;
}

const PageFaqCategory = (props: IProps) => {
  const { pageFaqCategory } = props;
  return (
    <div className='centered-content vertical-margin'>
      <h2 className='element-title'>{pageFaqCategory.category}</h2>
      <FaqList faq={pageFaqCategory.faq} />
    </div>
  );
};

export default PageFaqCategory;
