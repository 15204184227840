// extracted by mini-css-extract-plugin
export var title = "PageBlogs-module--title--1bPEO";
export var blogCard = "PageBlogs-module--blogCard--2j4Oa";
export var info = "PageBlogs-module--info--2KAFl";
export var sliderContainer = "PageBlogs-module--sliderContainer--2EOHl";
export var blogContainer = "PageBlogs-module--blogContainer--s7m25";
export var slider = "PageBlogs-module--slider--ImQoA";
export var slide = "PageBlogs-module--slide--1GMsS";
export var activeSlide = "PageBlogs-module--activeSlide--1_2vR";
export var image = "PageBlogs-module--image--fFJM2";
export var arrows = "PageBlogs-module--arrows--1xr7x";
export var leftArrow = "PageBlogs-module--leftArrow---QVPU";
export var mobileSlide = "PageBlogs-module--mobileSlide--3LqTt";
export var activeMobileSlide = "PageBlogs-module--activeMobileSlide--3_AP8";
export var buttonBack = "PageBlogs-module--buttonBack--1M6HG";
export var buttonNext = "PageBlogs-module--buttonNext--2Cah1";