import React from 'react';
import cx from 'classnames';

import * as styles from './PagePrivateTours.module.scss';
import { FluidImage, Link } from '../../components';
import { IPageContext, IPagePrivateTours, IImage } from '../../interfaces';

interface IProps {
  pagePrivateTours: IPagePrivateTours;
  pageContext: IPageContext;
}

interface IState {
  imageIndex: { [key: string]: number };
}

class PagePrivateTours extends React.Component<IProps, IState> {
  readonly state: IState = { imageIndex: {} };

  componentDidMount() {
    const { images } = this.props.pagePrivateTours;
    const imageIndex: { [key: string]: number } = {};
    images.forEach((img, index) => {
      imageIndex[img.id] = images.length - index;
    });
    this.setState({ imageIndex });
  }

  render() {
    const { title, subtitle, text, link, images } = this.props.pagePrivateTours;
    const { pageContext } = this.props;
    const sortedImages = images.sort(this.sortImages);

    return (
      <div className='vertical-margin' data-aos='fade-up'>
        <Link langPath={pageContext.langPath} to={link.page.slug}>
          <h2 className={cx('centered-content', 'element-title')}>{title}</h2>
        </Link>
        <div className={styles.container}>
          <div className={styles.background}>
            <div className={styles.backgroundColor} />
          </div>
          <div className={styles.content}>
            <div className={'centered-content'}>
              <div className={styles.main}>
                <strong>{subtitle}</strong>
                <p>{text.text}</p>
                <Link
                  langPath={pageContext.langPath}
                  to={link.page.slug}
                  className='basic-button'
                >
                  {link.title}
                </Link>
              </div>

              <div className={styles.imageContainer}>
                {sortedImages.map((img, index) => {
                  const style = {
                    left: 55 * index,
                    zIndex: images.length - index,
                    transform: `scale(${1 - 0.11 * index})`,
                  };

                  return index === 0 ? (
                    <Link
                      key={img.id}
                      langPath={pageContext.langPath}
                      to={link.page.slug}
                      className={styles.image}
                      style={style}
                    >
                      <FluidImage fluid={img.fluid} alt={img.title} />
                    </Link>
                  ) : (
                    <button
                      key={img.id}
                      className={styles.image}
                      style={style}
                      onClick={() => this.selectImage(img.id)}
                    >
                      <FluidImage fluid={img.fluid} alt={img.title} />
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  sortImages = (a: IImage, b: IImage) => {
    const { imageIndex } = this.state;
    const indexA = imageIndex[a.id] || 0;
    const indexB = imageIndex[b.id] || 0;
    return indexA < indexB ? 1 : indexA > indexB ? -1 : 0;
  };

  selectImage = (id: string) => {
    const { imageIndex } = this.state;
    let max = 0;
    Object.values(imageIndex).forEach((val) => {
      if (val > max) {
        max = val;
      }
    });
    imageIndex[id] = max + 1;
    this.setState({ imageIndex });
  };
}

export default PagePrivateTours;
