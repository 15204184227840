import React from 'react';
import GoogleMapReact from 'google-map-react';
import { IPagePickUpLocations } from '../../interfaces';
import Marker from './Marker';
import * as styles from './PagePickupLocations.module.scss';

const mapContainerStyle = {
  width: '100%',
  height: '663px',
};

interface IProps {
  pagePickupLocations: IPagePickUpLocations;
}

const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_KEY;

const PagePickupLocations = ({ pagePickupLocations }: IProps) => {
  const { title, url, mapCenter, zoom, locations } = pagePickupLocations;

  const [selectedMarker, setSelectedMarker] =
    React.useState<number | null>(null);

  return (
    <div className='centered-content vertical-margin' style={mapContainerStyle}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_MAPS_API_KEY,
          libraries: ['places'],
        }}
        defaultCenter={{
          lat: mapCenter.lat,
          lng: mapCenter.lon,
        }}
        defaultZoom={zoom}
        onclick={() => setSelectedMarker(null)}
      >
        {locations.map((marker, index) => (
          <Marker
            key={index}
            lat={marker.location.lat}
            lng={marker.location.lon}
            marker={marker}
            onDeselect={() => setSelectedMarker(null)}
            onSelect={() => setSelectedMarker(index)}
            selected={selectedMarker === index}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default PagePickupLocations;
