import React from 'react';
import cx from 'classnames';
import { navigate } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as styles from './PageBlogs.module.scss';
import { IPageContext, IPageBlogs, IBlogItem } from '../../interfaces';
import { Link, Desktop, Mobile, Icon, Image } from '../../components';

interface IExternalProps {
  pageBlogs: IPageBlogs;
  pageContext: IPageContext;
}

interface IState {
  activeSlide: number;
  ready: boolean;
  mouseDown: boolean;
  isDragging: boolean;
}

const slideWidth = 440;

class PageBlogs extends React.Component<IExternalProps, IState> {
  readonly state = {
    activeSlide: 0,
    ready: false,
    mouseDown: false,
    isDragging: false,
  };

  componentDidMount() {
    const { pageBlogs } = this.props;
    // if there are no pageBlogs then we will return null
    if (pageBlogs) {
      const { blogs } = pageBlogs;
      const center = blogs.length > 0 ? Math.floor((blogs.length - 1) / 2) : 0;
      this.setState({ activeSlide: center, ready: true });
    }
  }

  render() {
    const { pageBlogs, /* blogs, */ pageContext } = this.props;
    // if there are no pageBlogs then return null
    if (!pageBlogs) {
      return null;
    }
    const { title, subtitle, link, blogs } = pageBlogs;
    const { activeSlide } = this.state;
    const activeBlog = blogs[activeSlide];
    return (
      <div
        className={cx('vertical-margin', styles.blogCard)}
        data-aos='fade-up'
      >
        <div className={cx('element-title', 'centered-content', styles.title)}>
          {link ? (
            <Link langPath={pageContext.langPath} to={link.page.slug}>
              <h2>{title}</h2>
            </Link>
          ) : (
            <h2>{title}</h2>
          )}
          {subtitle && <p>{subtitle}</p>}
        </div>

        <Mobile breakpoint='tablet'>{this.renderMobileSlider()}</Mobile>
        <Desktop breakpoint='tablet'>{this.renderSlider()}</Desktop>

        <div className={cx('element-title', 'centered-content', styles.info)}>
          <h3>{activeBlog.title}</h3>
          {activeBlog.summary && <p>{activeBlog.summary.summary}</p>}
          <Link
            langPath={pageContext.langPath}
            to={`${activeBlog.pathPrefix}/${activeBlog.slug}`}
          >
            {pageBlogs.readBlogText}
          </Link>
        </div>
      </div>
    );
  }

  renderSlider() {
    const { pageBlogs } = this.props;
    const { blogs } = pageBlogs;

    const sliderTransform = `translateX(-${
      this.state.activeSlide * slideWidth
    }px)`;

    if (blogs.length > 1) {
      return (
        <div className={cx(styles.sliderContainer, styles.blogContainer)}>
          <div className={styles.arrows}>
            <div>
              <button
                className={styles.buttonBack}
                onClick={this.prevSlide}
                aria-label='Previous'
              >
                <Icon name='ARROWLEFT' />
              </button>
              <button
                className={styles.buttonNext}
                onClick={this.nextSlide}
                aria-label='Next'
              >
                <Icon name='ARROWRIGHT' />
              </button>
            </div>
          </div>
          <div className={styles.slider} style={{ transform: sliderTransform }}>
            {blogs.map((blog, index) => this.renderSlide(blog, index))}
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.sliderContainer}>
          {blogs.map((blog, index) => this.renderSlide(blog, index))}
        </div>
      );
    }
  }

  renderSlide(blog: IBlogItem, index: number) {
    const { activeSlide } = this.state;
    const setActive = () => this.setState({ activeSlide: index });

    const image = blog.mainImage ? (
      <>
        <Image
          fluidImage={{
            ...blog.mainImage.fluid,
            fluid: {
              ...(blog.mainImage ? blog.mainImage.fluid : null),
              sizes: slideWidth + 'px',
              alt: blog.mainImage.title,
            },
          }}
          productPhoto={null}
          className={styles.image}
        />
      </>
    ) : null;

    if (index === activeSlide) {
      return (
        <div key={blog.id} className={cx(styles.slide, styles.activeSlide)}>
          <Link
            langPath={this.props.pageContext.langPath}
            to={`${blog.pathPrefix}/${blog.slug}`}
          >
            {image}
          </Link>
        </div>
      );
    }

    return (
      <div key={blog.id} className={styles.slide} onClick={setActive}>
        {image}
      </div>
    );
  }

  renderMobileSlider() {
    const { pageBlogs } = this.props;
    const { blogs } = pageBlogs;
    const { activeSlide, ready } = this.state;

    // If initial slide hasn't been found
    if (!ready) {
      return null;
    }

    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      initialSlide: activeSlide,
    };

    return (
      <div className={styles.blogContainer}>
        <Slider {...settings} afterChange={this.updateActive}>
          {blogs.map((blog, index) => this.renderMobileSlide(blog, index))}
        </Slider>
      </div>
    );
  }

  renderMobileSlide(blog: IBlogItem, index: number) {
    const { activeSlide } = this.state;

    const image = blog.mainImage ? (
      <Image
        fluidImage={{
          ...blog.mainImage.fluid,
          fluid: {
            ...(blog.mainImage ? blog.mainImage.fluid : null),
            sizes: '90vw',
            alt: blog.mainImage.title,
          },
        }}
        productPhoto={null}
        className={styles.image}
      />
    ) : null;

    if (index === activeSlide) {
      return (
        <div
          key={blog.id}
          className={cx(styles.mobileSlide, styles.activeMobileSlide)}
          onMouseDown={this.mouseDown}
          onMouseUp={() => this.mouseUp(blog)}
          onMouseMove={this.mouseMove}
        >
          {image}
        </div>
      );
    }

    return (
      <div key={blog.id} className={styles.mobileSlide}>
        {image}
      </div>
    );
  }

  nextSlide = () => {
    const { pageBlogs } = this.props;
    const { blogs } = pageBlogs;
    const { activeSlide } = this.state;

    if (activeSlide < blogs.length - 1) {
      this.setState({ activeSlide: activeSlide + 1 });
    } else {
      this.setState({ activeSlide: 0 });
    }
  };

  prevSlide = () => {
    const { pageBlogs } = this.props;
    const { blogs } = pageBlogs;
    const { activeSlide } = this.state;

    if (activeSlide > 0) {
      this.setState({ activeSlide: activeSlide - 1 });
    } else {
      this.setState({ activeSlide: blogs.length - 1 });
    }
  };

  updateActive = (index: number) => {
    this.setState({ activeSlide: index });
  };

  mouseDown = () => {
    this.setState({ mouseDown: true });
  };

  mouseUp = (blog: IBlogItem) => {
    const { langPath } = this.props.pageContext;
    if (!this.state.isDragging) {
      navigate(langPath + `${blog.pathPrefix}/${blog.slug}`);
    }
    this.setState({ mouseDown: false, isDragging: false });
  };

  mouseMove = () => {
    if (this.state.mouseDown) {
      this.setState({ isDragging: true });
    }
  };
}

export default PageBlogs;
