import React from 'react';
import * as styles from './InfoBubble.module.scss';
import { IMarker } from '../../interfaces';

interface IProps {
  marker: Marker;
  onClose: () => void;
}

const GOOGLE_MAPS_BASE_URL = 'https://www.google.com/maps/place';

export default function InfoBubble({ marker, onClose }: IProps) {
  return (
    <div className={styles.infoBubble}>
      <div className={styles.header}>
        <p>{marker.title}</p>
        <button
          className={styles.closeButton}
          onClick={onClose}
          className={styles.closeBtn}
        >
          ×
        </button>
      </div>
      <div className={styles.body}>
        {marker?.description &&
          marker?.description
            .split(/\n/)
            .map((line) => <p key={line}>{line}</p>)}
      </div>

      <div className={styles.footer}>
        <a
          href={`${GOOGLE_MAPS_BASE_URL}/${marker.location.lat},${marker.location.lon}`}
          target='_blank'
          rel='noreferrer'
          className={styles.directionsLink}
        >
          Directions
        </a>
      </div>
    </div>
  );
}
