// extracted by mini-css-extract-plugin
export var container = "MosaicPiece-module--container--3K-2E";
export var header = "MosaicPiece-module--header--1DJb_";
export var tourItem = "MosaicPiece-module--tourItem--2CP3B";
export var tourItemBig = "MosaicPiece-module--tourItemBig--3qg06";
export var imageContainer = "MosaicPiece-module--imageContainer--2EIqs";
export var image = "MosaicPiece-module--image--vKinp";
export var imageContainerSmall = "MosaicPiece-module--imageContainerSmall--1u6WZ";
export var mobileImageContainer = "MosaicPiece-module--mobileImageContainer--2ihYj";
export var imageMobile = "MosaicPiece-module--imageMobile--2DdnL";
export var card = "MosaicPiece-module--card--1kU7D";
export var smallCard = "MosaicPiece-module--smallCard--1_jKm";
export var smallPieceContainer = "MosaicPiece-module--smallPieceContainer--1Eyjq";
export var noPictureCard = "MosaicPiece-module--noPictureCard--2ciF4";
export var category = "MosaicPiece-module--category--1qLx8";
export var title = "MosaicPiece-module--title--3qqW8";
export var titleSlider = "MosaicPiece-module--titleSlider--2toEE";