import React from 'react';
import cx from 'classnames';

import { IPageContext, IPageMosaic } from '../../interfaces';

import MosaicPiece from '../../components/MosaicPiece/MosaicPiece';

interface IProps {
  pageContext: IPageContext;
  mosaic: IPageMosaic;
}

class PageMosaic extends React.Component<IProps> {
  render() {
    const { mosaic, pageContext } = this.props;
    if (!!mosaic.categoriesWithPictures && !!mosaic.categoriesWithoutPictures) {
      return (
        <div
          data-aos='fade-up'
          className={cx('centered-content', 'vertical-margin')}
        >
          <MosaicPiece
            bigPieces={mosaic.categoriesWithPictures}
            smallPieces={mosaic.categoriesWithoutPictures}
            header={mosaic.pageMosaic}
            langPath={pageContext.langPath}
          />
        </div>
      );
    }
    return null;
  }
}

export default PageMosaic;
