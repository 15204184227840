import React from 'react';

import {
  PageTours,
  PageWysiwygs,
  PageStaffDepartments,
  PageFaqCategory,
  PageBlogs,
  PageAirportTransfer,
  PageFlybus,
  PagePrivateTours,
  PageEmailForm,
  PagePickupLocations,
  PageMosaic,
  PageSupport,
} from '../../elements';
import {
  IPageElement,
  IPageTours,
  IPageWysiwyg,
  IPageFaqCategory,
  IPageStaffDepartments,
  IPageContext,
  IPageBlogs,
  IPageAirportTransfer,
  IPagePrivateTours,
  IPageEmailForm,
  IPageMosaic,
  IPagePrivateToursOnly,
  IPageSupport,
  IPagePickUpLocations,
} from '../../interfaces';
import PagePrivateToursOnly from '../PagePrivateToursOnly/PagePrivateToursOnly';

interface IProps {
  elements?: IPageElement[];
  pageContext: IPageContext;

  carousel?: boolean;
}

function renderElements(
  elements: IPageElement[],
  pageContext: IPageContext,
  carousel?: boolean
) {
  return elements.map((elem) => {
    switch (elem.__typename) {
      case 'ContentfulPageMosaic': {
        const pageMosaic = elem as IPageMosaic;
        return (
          <PageMosaic
            key={elem.id}
            pageContext={pageContext}
            mosaic={pageMosaic}
          />
        );
      }
      case 'ContentfulPageTours': {
        const pageTours = elem as IPageTours;
        return (
          <PageTours
            key={elem.id}
            pageContext={pageContext}
            pageTours={pageTours}
            carousel={carousel}
          />
        );
      }

      case 'ContentfulPageWysiwyg': {
        const pageWysiwyg = elem as IPageWysiwyg;
        return <PageWysiwygs key={elem.id} pageWysiwyg={pageWysiwyg} />;
      }
      case 'ContentfulPageStaffDepartments': {
        const pageStaffDepartments = elem as IPageStaffDepartments;
        return (
          <PageStaffDepartments
            key={elem.id}
            pageStaffDepartments={pageStaffDepartments}
          />
        );
      }

      case 'ContentfulPageFaqCategories': {
        const pageFaqCategory = elem as IPageFaqCategory;
        return (
          <PageFaqCategory key={elem.id} pageFaqCategory={pageFaqCategory} />
        );
      }

      case 'ContentfulPageBlog': {
        const pageBlogs = elem as IPageBlogs;
        return (
          <PageBlogs
            key={elem.id}
            pageContext={pageContext}
            pageBlogs={pageBlogs}
          />
        );
      }
      case 'ContentfulPageAirportTransfer': {
        const pageAirportTransfer = elem as IPageAirportTransfer;
        return (
          <PageAirportTransfer
            key={elem.id}
            pageContext={pageContext}
            pageAirportTransfer={pageAirportTransfer}
          />
        );
      }
      case 'ContentfulPageFlybus': {
        const pageFlybus = elem as IPageAirportTransfer;
        return (
          <PageFlybus
            key={elem.id}
            pageContext={pageContext}
            pageFlybus={pageFlybus}
          />
        );
      }
      case 'ContentfulPagePrivateTours': {
        const pagePrivateTours = elem as IPagePrivateTours;
        return (
          <PagePrivateTours
            key={elem.id}
            pageContext={pageContext}
            pagePrivateTours={pagePrivateTours}
          />
        );
      }
      case 'ContentfulPagePrivateToursOnly': {
        const pageTours = elem as IPagePrivateToursOnly;
        return (
          <PagePrivateToursOnly
            key={elem.id}
            pageContext={pageContext}
            pageTours={pageTours}
            carousel={carousel}
          />
        );
      }
      case 'ContentfulPageEmailForm': {
        const pageEmailForm = elem as IPageEmailForm;
        return <PageEmailForm key={elem.id} pageEmailForm={pageEmailForm} />;
      }
      case 'ContentfulPageSupport': {
        const pageSupport = elem as IPageSupport;
        return <PageSupport key={elem.id} pageSupport={pageSupport} />;
      }
      case 'ContentfulPagePickupLocations':
        // eslint-disable-next-line no-case-declarations
        const pagePickupLocations = elem as IPagePickUpLocations;
        return (
          <PagePickupLocations
            key={elem.id}
            pagePickupLocations={pagePickupLocations}
          />
        );
      default:
        return null;
    }
  });
}

const PageElements: React.FunctionComponent<IProps> = ({
  elements,
  pageContext,
  carousel,
}) => {
  return (
    <React.Fragment>
      {elements ? renderElements(elements, pageContext, carousel) : null}
    </React.Fragment>
  );
};

export default PageElements;
