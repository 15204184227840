import React from 'react';
import cx from 'classnames';

import * as styles from './MosaicPiece.module.scss';
import { Link, Image, Desktop, Mobile } from '../index';
import { ITourCategory, ITourCategorypicture } from '../../interfaces';

const PATH_PREFIX = 'tours-activities/';

interface IColumns {
  [index: number]: ITourCategory[];
}

interface IProps {
  bigPieces: ITourCategory[];
  smallPieces: ITourCategory[];
  header: string;
  langPath: string;
}

interface IState {
  columns?: IColumns;

  mobileArray: ITourCategory[];
}

class MosaicPiece extends React.Component<IProps, IState> {
  state = {
    columns: {} as ITourCategory[][],
    mobileArray: {} as ITourCategory[],
  };
  componentDidMount() {
    const { bigPieces, smallPieces } = this.props;
    this.calculateLocationInMosaic(bigPieces);
    this.createMobileArray(bigPieces, smallPieces);
  }

  render() {
    const { smallPieces, header } = this.props;
    const { columns, mobileArray } = this.state;
    return (
      <React.Fragment>
        <Mobile>
          <h2 className={styles.header}>{header}</h2>
          {mobileArray.length > 0 ? (
            <div
              className={cx(
                'columns',
                'is-multiline',
                'is-variable',
                'is-2',
                'is-mobile',
                styles.smallPieceContainer,
                styles.container
              )}
            >
              {mobileArray.map((mobilePiece: ITourCategory, index: number) => {
                return index < 8
                  ? this.renderMobileMosiac(mobilePiece)
                  : this.renderSmallPiece(mobilePiece, true);
              })}
            </div>
          ) : null}
        </Mobile>
        <Desktop>
          <h2
            // className='element-title'
            className={styles.header}
          >
            {header}
          </h2>
          {columns.length > 0 ? (
            <div className={cx('columns', 'is-multiline', styles.container)}>
              {columns.map((column: ITourCategory[], index: number) =>
                this.renderMosaicColumn(column, index)
              )}
            </div>
          ) : null}
          {smallPieces ? (
            <div
              className={cx(
                'columns',
                'is-multiline',
                styles.container,
                styles.smallPieceContainer
              )}
            >
              {smallPieces.map((smallPiece) =>
                this.renderSmallPiece(smallPiece, false)
              )}
            </div>
          ) : null}
        </Desktop>
      </React.Fragment>
    );
  }

  renderMosaicColumn(column: ITourCategory[], index: number) {
    return (
      <div
        key={index}
        className={cx('column', 'is-6 is-3-desktop', styles.tourItem)}
      >
        {column.map((category: ITourCategory) =>
          this.renderBigPiece(category, column.length)
        )}
      </div>
    );
  }

  renderBigPiece(category: ITourCategory, numberInColumn: number) {
    const { slug, title, id } = category;
    const { langPath } = this.props;
    const margin = 10 / (numberInColumn - 1);
    return (
      <div key={id} style={{ position: 'relative', marginBottom: margin }}>
        <Link
          langPath={langPath}
          to={category.landingPage ? slug : PATH_PREFIX}
          queryParams={category.landingPage ? '' : `?type=${slug}`}
        >
          {category.categoryPicture
            ? this.renderImage(
                category.categoryPicture,
                false,
                category.mosaicSize
              )
            : null}

          <div
            className={
              category.mosaicSize === 1 ? styles.smallCard : styles.card
            }
          >
            <p className={styles.title}>{title}</p>
          </div>
        </Link>
      </div>
    );
  }

  renderSmallPiece(smallPiece: ITourCategory, mobile: boolean) {
    const { langPath } = this.props;
    const { slug, title, id } = smallPiece;
    const desk = mobile ? 'is-half ' : 'is-6 is-3-desktop';

    return (
      <div key={id} className={cx('column', desk, styles.tourItem)}>
        <div className={styles.noPictureCard}>
          <Link
            langPath={langPath}
            to={smallPiece.landingPage ? slug : PATH_PREFIX}
            queryParams={smallPiece.landingPage ? '' : `?type=${slug}`}
          >
            <p className={styles.title}>{title}</p>
          </Link>
        </div>
      </div>
    );
  }

  renderImage(
    picture: ITourCategorypicture,
    mobile: boolean,
    size: number | undefined
  ) {
    return (
      <div
        className={
          mobile
            ? styles.mobileImageContainer
            : size === 1
            ? styles.imageContainerSmall
            : styles.imageContainer
        }
      >
        <Image
          fluidImage={{
            ...picture,
            fluid: {
              ...(picture || {}).fluid,
              sizes: mobile ? '(max-width: 950px) 50vw, 950px' : '25vw',
            },
          }}
          productPhoto={null}
          // loadingProduct={loading}
          productPhotoSize='large'
          className={mobile ? styles.imageMobile : styles.image}
        />
      </div>
    );
  }

  renderMobileMosiac(category: ITourCategory) {
    const { id, slug, title } = category;
    const { langPath } = this.props;

    return (
      <div
        key={id}
        className={cx(
          'column',
          'is-half',
          styles.tourItem,
          styles.tourItemBig
        )} /*style={{ position: 'relative', marginBottom: '5px' }}*/
      >
        <Link
          langPath={langPath}
          to={category.landingPage ? slug : PATH_PREFIX}
          queryParams={category.landingPage ? '' : `?type=${slug}`}
        >
          {category.categoryPicture
            ? this.renderImage(category.categoryPicture, true, 0)
            : null}

          <div className={styles.smallCard}>
            {/*<p className={styles.category}>{category.title.toUpperCase()}</p>*/}
            <p className={styles.title}>{title}</p>
          </div>
        </Link>
      </div>
    );
  }

  calculateLocationInMosaic(categories: ITourCategory[]) {
    // large mosaic pieces take up 2 spaces, small ones take up 1 space
    let placeLeft = 12; // Total places in the mosaic is 12
    const placeLeftInColumn = [3, 3, 3, 3]; // max room in each column is 3
    let currentColumn = 0; // start by filling the first column

    // add the correct category to the 2d array to order them correctly on the site.
    const columns: ITourCategory[][] = [[], [], [], []];

    categories.map((category: ITourCategory, index: number) => {
      let sortedInColumn = false;
      const piecesLeft = categories.length - index;

      // in case someone puts a category without a given picture size or sets the size to 0
      if (category.mosaicSize === 0 || !category.mosaicSize) {
        category.mosaicSize = 1;
      }

      // if the places left ever equal the categories left in categories then all
      // subsequent mosaic pieces should have mosaic size 1
      if (piecesLeft === placeLeft) {
        category.mosaicSize = 1;
      }
      // check if there are places left
      if (placeLeft < 1) {
        // if there are no spaces left then we cant add more mosaic pieces.
        return;
      }

      // while the category does not have a column run this loop
      // the currentColumn check is a failsafe
      while (!sortedInColumn && currentColumn < 4) {
        // get places left in current column
        const currentColumnPlaces = placeLeftInColumn[currentColumn];

        // check if this piece fits in this column
        if (currentColumnPlaces - category.mosaicSize > -1) {
          // if it fits then set sortedInColumn to true
          sortedInColumn = true;
          // also add it to a double array in categories
          columns[currentColumn].push(category); // TODO
          placeLeftInColumn[currentColumn] -= category.mosaicSize;
          placeLeft -= category.mosaicSize;
        } else {
          // if it doesn't fit then we try the next column
          currentColumn++;
        }
      }

      // finally set currentColumn to the first column with spaces left
      currentColumn = placeLeftInColumn.findIndex((el) => {
        return el > 0;
      });
    });
    // finally add the columns double array to state for later rendering
    this.setState({
      columns,
    });
  }

  createMobileArray(bigPieces: ITourCategory[], smallPieces: ITourCategory[]) {
    const mobileArray = bigPieces.concat(smallPieces);
    this.setState({
      mobileArray,
    });
  }
}

export default MosaicPiece;
