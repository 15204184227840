import React from 'react';
import cx from 'classnames';

import * as styles from './PageWysiwyg.module.scss';
import { IPageWysiwyg } from '../../interfaces';
import { TextParser } from '../../components';

interface IProps {
  pageWysiwyg: IPageWysiwyg;
}

const PageWysiwyg = (props: IProps) => {
  const { text, numberOfColumns } = props.pageWysiwyg;
  const twoColumns = !numberOfColumns || numberOfColumns === 2;
  return (
    <div className='centered-content vertical-margin'>
      <TextParser
        source={text.text}
        className={cx(styles.text, {
          [styles.twoColumns]: twoColumns,
        })}
      />
    </div>
  );
};

export default PageWysiwyg;
