import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';

import {
  IPageContext,
  IPageTours,
  IReducers,
  ICurrencyOption,
  ITourListItem
} from '../../interfaces';
import { TourList, Link } from '../../components';
import { ProductApi, TransportApi } from '../../api';
import { findDefaultPrice, findDefaultTransportPrice } from '../../utils';

interface IProps {
  pageTours: IPageTours;
  pageContext: IPageContext;
  darkMode?: boolean;
  currency: ICurrencyOption;
  carousel?: boolean | false;
  lang: string;
}

interface IState {
  tours: ITourListItem[];
  loading: boolean;
}

class PageTours extends React.Component<IProps, IState> {
  productApi = new ProductApi();
  transportApi = new TransportApi();

  constructor(props: IProps) {
    super(props);
    const { pageTours } = props;

    const tours = pageTours.tours.map(tour => ({
      contentfulTour: tour,
      product: null
    }));
    this.state = { tours, loading: false };
  }

  componentDidMount() {
    this.getDefaultPrices();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.currency !== this.props.currency) {
      this.getDefaultPrices();
    }
  }

  render() {
    const { pageContext, currency, carousel } = this.props;
    const { tours, loading } = this.state;
    return (
      <div
        data-aos='fade-up'
        className={cx('centered-content', 'vertical-margin')}
      >
        {this.renderTitle()}
        <TourList
          tours={tours}
          loading={loading}
          langPath={pageContext.langPath}
          currency={currency}
          carousel={carousel}
        />
      </div>
    );
  }

  renderTitle() {
    const { pageTours, darkMode, pageContext } = this.props;
    const { title, link, queryParams } = pageTours;
    const text = (
      <h2 className='element-title' style={darkMode ? { color: 'white' } : {}}>
        {title}
      </h2>
    );

    return link ? (
      <Link
        langPath={pageContext.langPath}
        to={link.slug}
        queryParams={queryParams}
      >
        {text}
      </Link>
    ) : (
      text
    );
  }

  getDefaultPrices = async () => {
    this.setState({ loading: true });
    const { pageTours, lang } = this.props;
    let { tours }: IState = this.state;

    const defaultPrices = await this.productApi.getDefaultPrices(
      pageTours.tours.map(tour => tour.productId),
      this.props.currency.value,
      lang
    );

    if (defaultPrices) {
      tours = pageTours.tours.map(tour => ({
        contentfulTour: tour,
        product: findDefaultPrice(defaultPrices, tour.productId)
      }));
    }

    await this.getTransportPrices(tours);

    this.setState({ loading: false, tours });
  };

  getTransportPrices = async (tours: ITourListItem[]) => {
    const { currency, lang } = this.props;
    const defaultPrices = await this.transportApi.getDefaultPrices(
      tours.map(tour => tour.contentfulTour.productId),
      currency.value,
      lang
    );

    if (defaultPrices) {
      tours.forEach(tour => {
        const transport = findDefaultTransportPrice(
          defaultPrices,
          tour.contentfulTour.productId
        );
        if (transport) {
          if (transport.DefaultPrice) {
            tour.transportPrice = transport.DefaultPrice;
          }
          if (transport.KeyPhoto) {
            tour.transportImage = transport.KeyPhoto;
          }
        }
      });
    }
  };
}

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { lang } = reducers.translationReducers;
  return { currency, lang };
};

export default connect(mapStateToProps)(PageTours);
