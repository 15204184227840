import React from 'react';

import { IPageSupport } from '../../interfaces';
import { SupportCards } from '../../components';
import * as styles from './index.module.scss';
interface IProps {
  pageSupport: IPageSupport;
}

const PageSupport = (props: IProps) => {
  const { pageSupport } = props;

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.center}>
          <h2 className='element-title'>{pageSupport.header}</h2>
          <p>{pageSupport.shortDescription}</p>
        </div>
      </div>
      <div className={styles.flexParent}>
        <div className={styles.flex}>
          <SupportCards cards={pageSupport.supportCards}></SupportCards>
        </div>
      </div>
    </div>
  );
};

export default PageSupport;
